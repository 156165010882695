import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VScaleTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"media-preview-list__actions"},[_c('div',{staticClass:"media-preview-list_actions--top"},[(_vm.isImage)?_c(VTooltip,{attrs:{"bottom":"","disabled":_vm.disableFetureChange},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VScaleTransition,{attrs:{"origin":"center"}},[_c(VBtn,_vm._g(_vm._b({staticClass:"media-preview-list__featured-btn",class:{
                            featured: _vm.resource.featured,
                            'd-none': !_vm.allowEdit && !_vm.resource.featured
                        },attrs:{"fab":"","x-small":"","color":_vm.resource.featured ? 'primary' : '',"transition":"scale-transition"},on:{"click":function($event){$event.preventDefault();return _vm.feature(_vm.resource)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("star")])],1)],1)]}}],null,false,2298546643)},[_c('span',[_vm._v(_vm._s(_vm.getTooltip(_vm.resource)))])]):_vm._e(),_c(VSpacer),(_vm.allowEdit)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-1",attrs:{"fab":"","color":"primary","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.replace(_vm.resource)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"x-small":"","color":"white"}},[_vm._v("retweet")])],1)]}}],null,false,2927385363)},[_c('span',[_vm._v("Replace")])]):_vm._e(),(_vm.allowDelete)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"fab":"","color":"secondary","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.remove(_vm.resource)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"x-small":"","color":"white"}},[_vm._v("trash")])],1)]}}],null,false,2548909552)},[_c('span',[_vm._v("Remove")])]):_vm._e()],1),_c('div',{staticClass:"media-preview-list_actions--bottom"},[_c(VBtn,{attrs:{"rounded":"","x-small":"","transition":"scale-transition","width":"20","min-width":"20","elevation":"0","color":"primary lighten-2","title":_vm.resource.media_file.type === 0 ? 'Image' : 'Video'}},[_c(VIcon,{attrs:{"x-small":"","color":"white"}},[_vm._v(" "+_vm._s(_vm.resource.media_file.type === 0 ? 'image' : 'video')+" ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }