<template>
    <modal
        id="user-account-media-preview"
        title="Edit Image"
        @close="$emit('close')"
    >
        <v-container class="media-edit--container pa-0">
            <v-row class="media-edit--wrapper ma-0">
                <v-col
                    cols="12"
                    sm="8"
                    class="media-edit--image-wrapper pl-0 py-0"
                >
                    <v-img
                        class="media-edit--image"
                        contain
                        :src="mediaResource.media_file.relative_filename"
                    />
                </v-col>

                <v-col
                    v-if="resource"
                    cols="12"
                    sm="4"
                    class="media-edit--form-wrapper py-0 mt-4 mt-sm-0 pl-0"
                >
                    <v-text-field
                        v-model="resource.media_file.title"
                        filled
                        label="Title"
                        color="secondary"
                        name="resource.media_file.title"
                        @change="updateMedia"
                    />

                    <v-textarea
                        v-model="resource.media_file.description"
                        filled
                        label="Description"
                        rows="4"
                        color="secondary"
                        name="resource.media_file.description"
                        @change="updateMedia"
                    />
                </v-col>
            </v-row>
        </v-container>
    </modal>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { FormMediaResource } from '@/types/Media';
import { ServerResponse } from '@/types/ServerResponse';

import { Modal } from '@/components/Modal';

const UserAccountMediaPreviewModalProps = Vue.extend({
    props: {
        mediaResource: {
            type: Object as PropType<FormMediaResource>,
            required: true
        }
    }
});

@Component({
    components: { Modal }
})
export default class UserAccountMediaPreviewModal extends UserAccountMediaPreviewModalProps {
    resource: FormMediaResource | null = null;

    mounted() {
        this.resource = this.mediaResource;
    }

    updateMedia() {
        this.$http
            .post<
                ServerResponse<void>
            >(`/media/update/${this.resource?.media_file.id}`, this.resource?.media_file)
            .then(({ data }) => {
                if (!data.meta.success) {
                    const message = data.meta.message
                        ? data.meta.message
                        : 'Unable to save file. Please reload and try again.';

                    this.$store.dispatch('notification/error', message);

                    return;
                }

                this.$emit('update', this.resource?.media_file);
            });
    }
}
</script>

<style lang="scss" scoped>
.media-edit--container::v-deep {
    height: 62vh;

    .media-edit--wrapper {
        height: 100%;

        .media-edit--image-wrapper {
            display: flex;
            height: 100%;

            .media-edit--image {
                background-color: $bonjour;
            }
        }
    }
}
</style>
