<template>
    <div class="media-preview-list__actions">
        <div class="media-preview-list_actions--top">
            <v-tooltip v-if="isImage" bottom :disabled="disableFetureChange">
                <template #activator="{ on, attrs }">
                    <v-scale-transition origin="center">
                        <v-btn
                            fab
                            x-small
                            v-bind="attrs"
                            :color="resource.featured ? 'primary' : ''"
                            transition="scale-transition"
                            class="media-preview-list__featured-btn"
                            :class="{
                                featured: resource.featured,
                                'd-none': !allowEdit && !resource.featured
                            }"
                            v-on="on"
                            @click.prevent="feature(resource)"
                        >
                            <v-icon small>star</v-icon>
                        </v-btn>
                    </v-scale-transition>
                </template>

                <span>{{ getTooltip(resource) }}</span>
            </v-tooltip>

            <v-spacer />

            <v-tooltip v-if="allowEdit" bottom>
                <template #activator="{ on, attrs }">
                    <v-btn
                        fab
                        color="primary"
                        x-small
                        class="mr-1"
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="replace(resource)"
                    >
                        <v-icon x-small color="white">retweet</v-icon>
                    </v-btn>
                </template>
                <span>Replace</span>
            </v-tooltip>

            <v-tooltip v-if="allowDelete" bottom>
                <template #activator="{ on, attrs }">
                    <v-btn
                        fab
                        color="secondary"
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="remove(resource)"
                    >
                        <v-icon x-small color="white">trash</v-icon>
                    </v-btn>
                </template>
                <span>Remove</span>
            </v-tooltip>
        </div>

        <div class="media-preview-list_actions--bottom">
            <v-btn
                rounded
                x-small
                transition="scale-transition"
                width="20"
                min-width="20"
                elevation="0"
                color="primary lighten-2"
                :title="resource.media_file.type === 0 ? 'Image' : 'Video'"
            >
                <v-icon x-small color="white">
                    {{ resource.media_file.type === 0 ? 'image' : 'video' }}
                </v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import type { FormMediaResource } from '@/types/Media';

const MediaPreviewListActionsProps = Vue.extend({
    props: {
        resource: {
            type: Object as PropType<FormMediaResource>,
            default() {
                return {} as FormMediaResource;
            }
        },
        allowEdit: {
            type: Boolean,
            default() {
                return true;
            }
        },
        allowDelete: {
            type: Boolean,
            default() {
                return true;
            }
        }
    }
});

@Component
export default class MediaPreviewListActions extends MediaPreviewListActionsProps {
    feature(resource: FormMediaResource) {
        this.$emit('feature', resource);
    }

    replace(resource: FormMediaResource) {
        this.$emit('replace', resource);
    }

    remove(resource: FormMediaResource) {
        this.$emit('remove', resource);
    }

    canBeFeatured(resource: FormMediaResource): boolean {
        return resource.media_file && resource.media_file.type === 0;
    }

    getTooltip(resource: FormMediaResource): string {
        if (this.allowEdit && this.canBeFeatured(resource)) {
            return resource.featured ? 'Featured' : 'Set As Featured';
        }

        return resource.media_file.title || '';
    }

    get isImage() {
        return this.resource.media_file.type === 0;
    }

    get disableFetureChange() {
        return !this.resource.media_file.title && !this.resource.featured;
    }
}
</script>
