<template>
    <modal
        :id="`media-dialog-${index}`"
        :title="title"
        @open="onOpen"
        @close="onClose"
    >
        <div class="mb-3">
            Select media below using the image checkboxes and click
            <strong>Confirm</strong>
        </div>

        <div class="media-dialog__navbar--container">
            <nav class="media-dialog__navbar--wrapper">
                <v-tooltip v-for="tab in tabs" :key="tab.type" bottom>
                    <template #activator="{ on, attrs }">
                        <a
                            v-if="tab.available"
                            v-bind="attrs"
                            :key="tab.type"
                            :class="{ active: activeTab === tab.type }"
                            class="media-dialog__navbar--link"
                            v-on="on"
                            @click.prevent="activate(tab.type)"
                        >
                            <v-icon medium>{{ tab.icon }}</v-icon>
                        </a>
                    </template>
                    <span>{{ tab.title }}</span>
                </v-tooltip>
            </nav>
        </div>

        <div class="media-dialog__content--container">
            <internal-stock-media
                v-if="isInternalStock"
                v-show="activeTab === 'internal'"
                ref="InternalStockMedia"
                :resources="internalStockMediaFiles"
                :loading="isSaving"
                @collected="onCollected"
                @saved="onCollected"
                @confirm="onConfirm"
            />

            <template v-if="allowUserImages">
                <user-account-media
                    v-show="activeTab === 'account'"
                    ref="UserAccountMedia"
                    :loading="isSaving"
                    @deleted="onMediaDeleted"
                    @collected="onCollected"
                    @saved="onCollected"
                    @confirm="onConfirm"
                />

                <stock-search-media
                    v-if="allowStockMedia"
                    v-show="activeTab === 'search'"
                    ref="StockSearchMedia"
                    :loading="isSaving"
                    @collected="onCollected"
                    @saved="onCollected"
                    @confirm="onConfirm"
                />
            </template>
        </div>
    </modal>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { ProvideReactive } from '@/utils/decorators';

import { Modal } from '@/components/Modal';
import { AMediaUploader } from '@/components/AForm/Inputs/AMediaUploader';

import { StockSearchMedia } from './StockSearchMedia';
import { UserAccountMedia } from './UserAccountMedia';
import { InternalStockMedia } from './InternalStockMedia';

import type {
    FormMediaResource,
    MediaAction,
    MediaDialogOptions,
    MediaNavControlOption
} from '@/types/Media';

const MediaDialogProps = Vue.extend({
    props: {
        action: {
            type: String as PropType<MediaAction>,
            required: true,
            default(): MediaAction {
                return 'Add';
            }
        },
        moduleTitle: {
            type: String,
            default(): string {
                return '';
            }
        },
        options: {
            type: Object as PropType<MediaDialogOptions>,
            default() {
                return {
                    announcement_id: 0,
                    video_media_box_id: 0,
                    video_media_box_files: [],
                    author_request_id: 0,
                    video_media_box_media_file_ids: null
                };
            }
        },
        allowStockVideoSearch: {
            type: Boolean,
            default() {
                return false;
            }
        },
        allowMultipleSelection: {
            type: Boolean,
            default() {
                return true;
            }
        },
        allowUserImages: {
            type: Boolean,
            default() {
                return true;
            }
        },
        allowStockMedia: {
            type: Boolean,
            default() {
                return true;
            }
        },
        index: {
            type: [String, Number],
            default() {
                return 0;
            }
        }
    }
});

@Component({
    components: {
        Modal,
        StockSearchMedia,
        UserAccountMedia,
        AMediaUploader,
        InternalStockMedia
    }
})
export default class MediaDialog extends MediaDialogProps {
    $refs!: {
        UserAccountMedia: InstanceType<typeof UserAccountMedia>;
        StockSearchMedia: InstanceType<typeof StockSearchMedia>;
        InternalStockMedia: InstanceType<typeof InternalStockMedia>;
    };

    activeTab: MediaNavControlOption['type'] = 'account';

    selected: FormMediaResource[] = [];

    isSaving = false;

    get title() {
        if (this.action === 'Add') {
            return ['Add Media', this.moduleTitle && `to ${this.moduleTitle}`]
                .filter(Boolean)
                .join(' ');
        }

        return 'Replace Media';
    }

    get tabs(): MediaNavControlOption[] {
        return [
            {
                type: 'internal',
                available: this.isInternalStock,
                icon: 'photo-video',
                title: 'Internal Media'
            },
            {
                type: 'account',
                available: this.allowUserImages,
                icon: 'folder',
                title: 'User Media'
            },
            {
                type: 'search',
                available: this.allowStockMedia,
                icon: 'search',
                title: 'Stock Media Search'
            }
        ];
    }

    get isInternalStock() {
        return Boolean(this.options.video_media_box_media_file_ids);
    }

    get internalStockMediaFiles() {
        return this.options.video_media_box_files || [];
    }

    @ProvideReactive('allowStockVideoSearch')
    get allowStockVideoSearchProvider() {
        return this.allowStockVideoSearch;
    }

    @ProvideReactive('allowMultipleSelection')
    get allowMultipleSelectionProvider() {
        return this.allowMultipleSelection;
    }

    @ProvideReactive('action')
    get actionProvider() {
        return this.action;
    }

    @ProvideReactive('options')
    get optionsProvider() {
        return this.options;
    }

    activate(tab: MediaNavControlOption['type']) {
        this.activeTab = tab;

        if (tab === 'search') {
            setTimeout(() => {
                this.$refs.StockSearchMedia.focus();
            }, 100);
        }
    }

    onOpen() {
        this.activeTab = this.isInternalStock ? 'internal' : 'account';
    }

    onClose() {
        this.selected = [];

        this.$refs.InternalStockMedia?.reset();
        this.$refs.UserAccountMedia?.reset();
        this.$refs.StockSearchMedia?.reset();
    }

    onCollected(mediaFiles: FormMediaResource[]) {
        this.selected = [...this.selected, ...mediaFiles];
    }

    onConfirm() {
        this.selected = [];

        if (this.isInternalStock) {
            this.$refs.InternalStockMedia.collect();
        } else {
            this.$refs.StockSearchMedia.collect();

            this.$refs.UserAccountMedia.collect();
        }

        this.emitByAction('confirm');
    }

    async save() {
        this.isSaving = true;

        this.selected = [];

        if (this.isInternalStock) {
            this.$refs.InternalStockMedia.save();
        } else {
            await this.$refs.StockSearchMedia.save();

            this.$refs.UserAccountMedia.save();
        }

        this.emitByAction('saved');

        this.isSaving = false;
    }

    emitByAction(event: string) {
        if (this.action === 'Add') {
            this.$emit(event, this.selected);
        } else if (this.action === 'Replace') {
            // picks the last selected
            this.$emit(event, [...this.selected].reverse());
        }
    }

    onMediaDeleted(ids: number[]) {
        this.$emit('deleted', ids);
    }
}
</script>

<style lang="scss" scoped>
.media-dialog__navbar--container {
    margin-bottom: 1em;
    .media-dialog__navbar--wrapper::v-deep {
        display: flex;
        border-bottom: solid 2px $ebb;

        .media-dialog__navbar--link {
            float: left;
            padding: 1.25em;
            width: 10em;
            text-align: center;
            transition: all 0.2s ease;
            color: $doger-blue;
            text-decoration: none;

            &.active {
                background: $doger-blue;
                .v-icon {
                    svg {
                        path {
                            fill: white !important;
                            stroke: white !important;
                        }
                    }
                }
            }

            &:not(.active):hover {
                background: $faded-blue;
            }

            .v-icon {
                svg {
                    path {
                        fill: $doger-blue !important;
                        stroke: $doger-blue !important;
                    }
                }
            }
        }
    }
}

.media-dialog__content--container::v-deep {
    height: 55vh;
    overflow: hidden;
}

@media (max-width: 600px) {
    .media-dialog__navbar--container {
        .media-dialog__navbar--wrapper {
            .media-dialog__navbar--link {
                width: 50% !important;
            }
        }
    }
}
</style>
