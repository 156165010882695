<template>
    <v-container class="media-tab-container">
        <v-row class="stock-container">
            <v-col cols="12" class="search-form">
                <form @submit.prevent="search()">
                    <div class="input-group">
                        <input
                            ref="input"
                            v-model="searchTerm"
                            type="text"
                            placeholder="Search..."
                            required
                            :disabled="requesting"
                        />

                        <button type="submit" :disabled="requesting">
                            <v-icon small color="white">search</v-icon>
                        </button>
                    </div>
                </form>
            </v-col>

            <v-col class="result-tabs">
                <v-tabs v-model="activeResultTab">
                    <v-tab
                        key="videos"
                        :disabled="!allowStockVideoSearch || requesting"
                    >
                        Videos
                    </v-tab>

                    <v-tab key="images" :disabled="requesting">Images</v-tab>
                </v-tabs>
            </v-col>

            <v-col
                cols="12"
                class="results-wrapper"
                :class="{
                    'mobile-results-breakpoint': $vuetify.breakpoint.xsOnly
                }"
            >
                <v-tabs-items
                    v-model="activeResultTab"
                    class="result-tabs-items"
                >
                    <v-tab-item key="videos">
                        <stock-search-media-result
                            :stock-media="stockMedia.videos"
                            :fetching="pagination.fetching"
                            :selected-media="selected"
                            @checked="add"
                            @unchecked="remove"
                        />
                    </v-tab-item>

                    <v-tab-item key="images">
                        <stock-search-media-result
                            :stock-media="stockMedia.images"
                            :fetching="pagination.fetching"
                            :selected-media="selected"
                            @checked="add"
                            @unchecked="remove"
                        />
                    </v-tab-item>
                </v-tabs-items>
            </v-col>

            <v-col
                cols="12"
                class="media-tab-footer justify-space-between"
                :class="{
                    'd-block': $vuetify.breakpoint.xsOnly
                }"
            >
                <div
                    class="d-flex justify-start"
                    :class="{
                        'mb-2': $vuetify.breakpoint.xsOnly
                    }"
                >
                    <span
                        class="stock-search-media-providers text-h6 mr-4 pt-1"
                    >
                        Powered by
                    </span>
                    <a href="https://pexels.com" target="_blank">
                        <v-img
                            src="@/assets/img/media/pexels.svg"
                            max-height="40"
                            max-width="40"
                        />
                    </a>
                    <a href="https://pixabay.com" target="_blank">
                        <v-img
                            src="@/assets/img/media/pixabay.svg"
                            max-height="40"
                            max-width="40"
                            class="ml-4"
                        />
                    </a>
                </div>
                <v-btn
                    class="media-dialog__confirm-btn px-10"
                    color="primary"
                    large
                    :block="$vuetify.breakpoint.xsOnly"
                    :disabled="requesting || selected.length === 0"
                    :loading="loading"
                    @click="onConfirm"
                >
                    Confirm
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { InjectReactive, Watch } from '@/utils/decorators';

import { ServerResponse } from '@/types/ServerResponse';
import { setMediaResources } from '@/utils/helpers';

import MediaPreviewModal from '../MediaPreviewModal.vue';
import StockSearchMediaResult from './StockSearchMediaResult.vue';

import type { MediaFile } from '@/types/MediaFile';
import type { MediaAction, StockMedia } from '@/types/Media';

type StockMediaFiles = {
    videos: StockMedia[];
    images: StockMedia[];
};

const StockSearchMediaProps = Vue.extend({
    name: 'StockSearchMedia',
    props: {
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: { MediaPreviewModal, StockSearchMediaResult }
})
export default class StockSearchMedia extends StockSearchMediaProps {
    $refs!: {
        input: HTMLInputElement;
    };

    @InjectReactive({
        from: 'allowStockVideoSearch',
        default() {
            return true;
        }
    })
    allowStockVideoSearch!: boolean;

    @InjectReactive({
        from: 'allowMultipleSelection',
        default() {
            return true;
        }
    })
    allowMultipleSelection!: boolean;

    @InjectReactive({
        from: 'action',
        default() {
            return 'Add';
        }
    })
    action!: MediaAction;

    searchTerm = '';

    stockMedia: StockMediaFiles = {
        videos: [],
        images: []
    };

    @Watch('stockMedia', { immediate: true, deep: true })
    onChangeStockImages() {
        this.$nextTick(() => {
            this.setupScrollEvent();
        });
    }

    pagination = {
        fetching: false,
        page: 1
    };

    activeResultTab: 'videos' | 'images' = this.allowStockVideoSearch
        ? 'videos'
        : 'images';

    selected: StockMedia[] = [];

    get requesting(): boolean {
        return this.$store.state.loading.show;
    }

    add(media: StockMedia) {
        if (this.action === 'Replace' || !this.allowMultipleSelection) {
            this.selected = [media];
        } else {
            this.selected.push(media);
        }
    }

    remove(url: string) {
        this.selected = this.selected.filter(media => media.url !== url);
    }

    async save() {
        if (this.selected.length) {
            return this.$http
                .post<ServerResponse<{ mediaFiles: MediaFile[] }>>(
                    '/media/saveStockMedia',
                    {
                        mediaList: this.selected
                    }
                )
                .then(({ data }) => {
                    this.$emit(
                        'saved',
                        setMediaResources(data.data.mediaFiles)
                    );
                })
                .catch(error => {
                    if (!error.isIntercepted) {
                        this.$store.dispatch('notification/error', error);
                    }
                });
        }

        return Promise.resolve();
    }

    collect() {
        this.$emit('collected', this.selected);
    }

    reset() {
        this.searchTerm = '';

        this.selected = [];
    }

    setupScrollEvent() {
        const scrollEl = document.querySelector(
            '.media-stock-search__result-tabs-items'
        );

        scrollEl?.addEventListener('scroll', this.scrollListener);
    }

    scrollListener(e: Event) {
        const { scrollTop, scrollHeight, clientHeight, removeEventListener } =
            e.target as HTMLDivElement;

        if (
            Math.ceil(scrollTop) !== 0 &&
            scrollHeight - scrollTop === clientHeight
        ) {
            this.search(true);

            removeEventListener('scroll', this.scrollListener);
        }
    }

    async getMedia(searchTerm: string, type: 'video' | 'image') {
        try {
            try {
                const { data } = await this.$http.get<
                    ServerResponse<{ list: StockMedia[] }>
                >(
                    `/media/stock?page=${this.pagination.page}&keywords=${searchTerm}&type=${type}`
                );
                return data.data.list;
            } catch {
                this.$store.dispatch(
                    'notification/error',
                    'Unable to search. Please refresh the page and try again.'
                );
            }
        } finally {
            this.pagination.fetching = false;
        }
    }

    search(fetchMore = false) {
        this.pagination.fetching = true;

        if (fetchMore) {
            this.pagination.page = this.pagination.page + 1;
        } else {
            this.selected = [];

            this.stockMedia = {
                videos: [],
                images: []
            };

            this.pagination.page = 1;
        }

        if (this.allowStockVideoSearch) {
            this.getMedia(this.searchTerm, 'video').then(data => {
                if (fetchMore) {
                    this.stockMedia.videos = [
                        ...this.stockMedia.videos,
                        ...(data as StockMedia[])
                    ];
                } else {
                    this.stockMedia.videos = data as StockMedia[];
                }
            });
        }

        this.getMedia(this.searchTerm, 'image').then(data => {
            if (fetchMore) {
                this.stockMedia.images = [
                    ...this.stockMedia.images,
                    ...(data as StockMedia[])
                ];
            } else {
                this.stockMedia.images = data as StockMedia[];
            }
        });
    }

    onConfirm() {
        this.$emit('confirm');
    }

    focus() {
        this.$refs.input?.focus();
    }
}
</script>

<style lang="scss" scoped>
.media-tab-container::v-deep {
    height: 100%;

    .stock-container {
        height: inherit;
        align-content: flex-start;

        .search-form {
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;

            .input-group {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                input {
                    padding: 17px;
                    width: 80%;
                    font-size: 1rem;
                    background-color: $main-background;
                    transition: all 0.2s ease;
                    border-radius: 4px;
                    margin-right: 1em;
                    color: $secondary-color;

                    &:focus {
                        outline: none;
                    }

                    &::placeholder {
                        color: $secondary-color;
                    }
                }

                button {
                    width: 20%;
                    padding: 17px 0;
                    background: $doger-blue;
                    color: #f8f8f9;
                    border-radius: 4px;
                    border: none;
                    box-shadow: none;
                    font-size: large;
                    transition: all 0.2s ease;

                    &:hover {
                        opacity: 0.8;
                    }

                    &:disabled {
                        background-color: #e0e0e0;
                    }
                }
            }
        }

        .result-tabs {
            padding-top: 0;

            .v-tabs {
                .v-ripple__container {
                    display: none !important;
                }

                .v-tabs-bar {
                    height: 36px;

                    .v-tabs-slider-wrapper {
                        max-width: 52px;
                        color: $doger-blue;
                    }

                    .v-tab {
                        padding-left: 0;
                        text-transform: none;
                        min-width: 50px;
                        color: $secondary-color-light;

                        &.v-tab--active {
                            color: $doger-blue;
                        }

                        &:hover {
                            background: none;
                        }

                        &::before {
                            background-color: unset !important;
                        }
                    }
                }
            }
        }

        .results-wrapper {
            height: calc(100% - 8em);
            padding: 0;

            .result-tabs-items {
                &.v-tabs-items {
                    height: calc(100% - 2.75em);
                    overflow: auto;
                }
            }
        }

        .media-tab-footer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            width: 100%;
            right: 0;
            left: 0;
            bottom: 0;
            padding: 20px 24px;
        }
    }
}
</style>
