<template>
    <span>
        <div v-if="isCompanyLogo">
            This media is used as
            <a :href="companyLink" target="_blank">Company</a>
            logo. Please select another logo for that company in order to delete
            this media.
        </div>
        <div v-if="isResellerLogo">
            This media is used as
            <a :href="resellerEditLink" target="_blank">Reseller</a>
            logo. Please select another logo for in order to delete this media.
        </div>
        <div v-if="usage?.press_release">
            This media is used in
            <a :href="pressReleaseLink" target="_blank" class="d-inline-block">
                {{
                    usage.press_release.headline ||
                    usage.press_release.announcement_id
                }}
            </a>
            News article.
        </div>
        <div v-if="usage?.blog_post">
            This media is used in
            <a :href="blogPostLink" target="_blank" class="d-inline-block">
                {{
                    usage.blog_post.headline || usage.blog_post.announcement_id
                }}
            </a>
            Blog Post.
        </div>
        <div v-if="usage?.podcast">
            This media is used in
            <a :href="podcastLink" target="_blank" class="d-inline-block">
                {{ usage.podcast.headline || usage.podcast.announcement_id }}
            </a>
            Podcast.
        </div>
        <div v-if="usage?.slideshow">
            This media is used in
            <a :href="slideshowLink" target="_blank" class="d-inline-block">
                {{ usage.slideshow.title || usage.slideshow.announcement_id }}
            </a>
            Slideshow.
        </div>
        <div v-if="usage?.infographic">
            This media is used in
            <a :href="infographicLink" target="_blank" class="d-inline-block">
                {{
                    usage.infographic.title || usage.infographic.announcement_id
                }}
            </a>
            Infographic.
        </div>
        <div v-if="usage?.video_pr">
            This media is used in
            <a :href="videoLink" target="_blank" class="d-inline-block">
                {{ usage.video_pr.title || usage.video_pr.announcement_id }}
            </a>
            Video.
        </div>
    </span>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import type { MediaResourceUsage } from '@/types/Media';

const MediaUsageProps = Vue.extend({
    name: 'MediaUsage',
    props: {
        usage: {
            type: Object as PropType<MediaResourceUsage | null>,
            default(): MediaResourceUsage | null {
                return null;
            }
        }
    }
});

@Component({
    computed: {
        ...mapGetters('user', ['isReseller'])
    }
})
export default class MediaUsage extends MediaUsageProps {
    isReseller!: boolean;

    get isCompanyLogo() {
        return Boolean(this.usage?.company_id);
    }

    get companyLink() {
        return `/app/profile/companies/${this.usage?.company_id || ''}`;
    }

    get isResellerLogo() {
        return Boolean(
            this.usage?.reseller_id || this.usage?.reseller_profile_id
        );
    }

    get resellerEditLink() {
        return `/app/profile${this.isReseller ? '/whitelabel' : ''}`;
    }

    get pressReleaseLink() {
        return this.getLink(
            this.usage?.press_release?.announcement_id || '',
            'article'
        );
    }

    get blogPostLink() {
        return this.getLink(
            this.usage?.blog_post?.announcement_id || '',
            'blog-post'
        );
    }

    get podcastLink() {
        return this.getLink(
            this.usage?.podcast?.announcement_id || '',
            'podcast'
        );
    }

    get infographicLink() {
        return this.getLink(
            this.usage?.infographic?.announcement_id || '',
            'infographic'
        );
    }
    get slideshowLink() {
        return this.getLink(
            this.usage?.slideshow?.announcement_id || '',
            'slideshow'
        );
    }

    get videoLink() {
        return this.getLink(
            this.usage?.video_pr?.announcement_id || '',
            'video'
        );
    }

    getLink(ampId: string | number, module: string) {
        return `/app/announcements/edit/${ampId}/${module}`;
    }
}
</script>
