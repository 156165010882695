import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"media-tab-container"},[_c(VRow,{staticClass:"stock-container"},[_c(VCol,{staticClass:"media-result-container",attrs:{"cols":"12"}},[_c(VRow,{staticClass:"media-result-wrapper"},_vm._l((_vm.resources),function(resource){return _c(VCol,{key:`video-stock-${resource.media_file_id}`,staticClass:"media-wrapper",class:{
                        selected: _vm.isSelected(resource.media_file_id)
                    },attrs:{"cols":"6","sm":"3"}},[_c('div',{staticClass:"actions"},[(!_vm.isSelected(resource.media_file_id))?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"action-button action-select",attrs:{"icon":"","ripple":false},on:{"click":function($event){return _vm.select(resource)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"20px"}},[_vm._v("square")])],1)]}}],null,true)},[_c('span',[_vm._v("Click to select media")])]):_c(VBtn,{staticClass:"action-button action-deselect",attrs:{"icon":"","ripple":false},on:{"click":function($event){return _vm.deselect(resource.media_file_id)}}},[_c(VIcon,{attrs:{"size":"20px"}},[_vm._v("check-square")])],1)],1),_c(VImg,{staticClass:"media-preview",attrs:{"contain":"","src":_vm.sourceFor(resource)}})],1)}),1)],1),_c(VCol,{staticClass:"media-tab-footer",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"media-dialog__confirm-btn px-10",attrs:{"color":"primary","large":"","block":_vm.$vuetify.breakpoint.xs,"disabled":_vm.selected.length === 0,"loading":_vm.loading},on:{"click":_vm.confirm}},[_vm._v(" Confirm ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }