<template>
    <v-row class="media-library__actions--wrapper">
        <v-col cols="12" sm="6" class="media-library__action-buttons px-sm-0">
            <v-tooltip v-for="action in actions" :key="action.key" bottom>
                <template #activator="{ on, attrs }">
                    <v-btn
                        icon
                        class="media-library__action-button"
                        :disabled="action.disabled || requesting"
                        :ripple="false"
                        v-bind="attrs"
                        v-on="on"
                        @click="action.onClick"
                    >
                        <v-icon
                            :size="getIconSize(action)"
                            :disabled="action.disabled"
                            :ripple="false"
                        >
                            {{ action.icon }}
                        </v-icon>
                    </v-btn>
                </template>
                <span>{{ action.title }}</span>
            </v-tooltip>
        </v-col>

        <v-col
            cols="12"
            sm="6"
            class="media-library__uploader d-flex justify-sm-end py-0"
        >
            <div class="uploader-button" @click="browse">
                <v-btn fab x-small color="blue" elevation="0" class="mr-1">
                    <v-icon color="white">plus</v-icon>
                </v-btn>
                <span class="label text-body-1 font-weight-bold">
                    {{ uploaderLabel }}
                </span>
            </div>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import type { MediaFileAction } from '@/types/Media';

const UserAccountMediaActionsProps = Vue.extend({
    props: {
        actions: {
            type: Array as PropType<MediaFileAction[]>,
            default(): MediaFileAction[] {
                return [];
            }
        }
    }
});

@Component
export default class UserAccountMediaActions extends UserAccountMediaActionsProps {
    get uploaderLabel() {
        return this.$vuetify.breakpoint.mobile
            ? 'Add from gallery'
            : 'Add from computer';
    }

    get requesting() {
        return this.$store.state.loading.show;
    }

    browse() {
        this.$emit('browse');
    }

    getIconSize(action: MediaFileAction) {
        return action.key === 'unarchive' ? '22px' : '18px';
    }
}
</script>

<style lang="scss" scoped>
.media-library__actions--wrapper::v-deep {
    align-items: center;

    .media-library__action-buttons {
        display: flex;
        align-items: center;
        height: 4em;

        .media-library__action-button {
            color: $doger-blue;
        }
    }

    .media-library__uploader {
        .uploader-button {
            cursor: pointer;
            width: fit-content;

            &:hover {
                .label {
                    color: $primary-color;
                }
            }
        }
    }
}
</style>
