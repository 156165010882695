import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"media-tab-container"},[_c(VRow,{staticClass:"stock-container"},[_c(VCol,{staticClass:"search-form",attrs:{"cols":"12"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.search()}}},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchTerm),expression:"searchTerm"}],ref:"input",attrs:{"type":"text","placeholder":"Search...","required":"","disabled":_vm.requesting},domProps:{"value":(_vm.searchTerm)},on:{"input":function($event){if($event.target.composing)return;_vm.searchTerm=$event.target.value}}}),_c('button',{attrs:{"type":"submit","disabled":_vm.requesting}},[_c(VIcon,{attrs:{"small":"","color":"white"}},[_vm._v("search")])],1)])])]),_c(VCol,{staticClass:"result-tabs"},[_c(VTabs,{model:{value:(_vm.activeResultTab),callback:function ($$v) {_vm.activeResultTab=$$v},expression:"activeResultTab"}},[_c(VTab,{key:"videos",attrs:{"disabled":!_vm.allowStockVideoSearch || _vm.requesting}},[_vm._v(" Videos ")]),_c(VTab,{key:"images",attrs:{"disabled":_vm.requesting}},[_vm._v("Images")])],1)],1),_c(VCol,{staticClass:"results-wrapper",class:{
                'mobile-results-breakpoint': _vm.$vuetify.breakpoint.xsOnly
            },attrs:{"cols":"12"}},[_c(VTabsItems,{staticClass:"result-tabs-items",model:{value:(_vm.activeResultTab),callback:function ($$v) {_vm.activeResultTab=$$v},expression:"activeResultTab"}},[_c(VTabItem,{key:"videos"},[_c('stock-search-media-result',{attrs:{"stock-media":_vm.stockMedia.videos,"fetching":_vm.pagination.fetching,"selected-media":_vm.selected},on:{"checked":_vm.add,"unchecked":_vm.remove}})],1),_c(VTabItem,{key:"images"},[_c('stock-search-media-result',{attrs:{"stock-media":_vm.stockMedia.images,"fetching":_vm.pagination.fetching,"selected-media":_vm.selected},on:{"checked":_vm.add,"unchecked":_vm.remove}})],1)],1)],1),_c(VCol,{staticClass:"media-tab-footer justify-space-between",class:{
                'd-block': _vm.$vuetify.breakpoint.xsOnly
            },attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-start",class:{
                    'mb-2': _vm.$vuetify.breakpoint.xsOnly
                }},[_c('span',{staticClass:"stock-search-media-providers text-h6 mr-4 pt-1"},[_vm._v(" Powered by ")]),_c('a',{attrs:{"href":"https://pexels.com","target":"_blank"}},[_c(VImg,{attrs:{"src":require("@/assets/img/media/pexels.svg"),"max-height":"40","max-width":"40"}})],1),_c('a',{attrs:{"href":"https://pixabay.com","target":"_blank"}},[_c(VImg,{staticClass:"ml-4",attrs:{"src":require("@/assets/img/media/pixabay.svg"),"max-height":"40","max-width":"40"}})],1)]),_c(VBtn,{staticClass:"media-dialog__confirm-btn px-10",attrs:{"color":"primary","large":"","block":_vm.$vuetify.breakpoint.xsOnly,"disabled":_vm.requesting || _vm.selected.length === 0,"loading":_vm.loading},on:{"click":_vm.onConfirm}},[_vm._v(" Confirm ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }