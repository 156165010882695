import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,{staticClass:"results-container"},[_vm._l((_vm.stockMedia),function(media,index){return _c(VCol,{key:`media=${index}`,staticClass:"media-wrapper",class:{ selected: _vm.isSelected(media.url) },attrs:{"cols":"6","sm":"3"}},[_c('div',{staticClass:"actions"},[(!_vm.isSelected(media.url))?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"action--button action-select",attrs:{"icon":"","ripple":false,"disabled":_vm.requesting},on:{"click":function($event){return _vm.$emit('checked', media)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"20px"}},[_vm._v("square")])],1)]}}],null,true)},[_c('span',[_vm._v("Click to select media")])]):_c(VBtn,{staticClass:"action--button action-deselect",attrs:{"icon":"","ripple":false,"disabled":_vm.requesting},on:{"click":function($event){return _vm.$emit('unchecked', media.url)}}},[_c(VIcon,{attrs:{"size":"20px"}},[_vm._v("check-square")])],1)],1),_c(VImg,{key:`img-${index}`,staticClass:"media-preview",attrs:{"src":media.thumbnail},on:{"click":function($event){return _vm.preview(media)}}})],1)}),(_vm.fetching)?_vm._l((Array.from(Array(4).keys())),function(index){return _c(VCol,{key:`skeleton-${index}`,staticClass:"media-loading",attrs:{"cols":"6","sm":"3"}},[_c(VSkeletonLoader,{attrs:{"elevation":"2","type":"image","height":"100%"}})],1)}):_vm._e(),(_vm.previewMedia)?_c('media-preview-modal',{attrs:{"media":_vm.previewMedia},on:{"close":function($event){return _vm.preview(null)}}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }