<template>
    <modal
        id="previewMedia"
        title="Preview"
        :persistent="true"
        @close="$emit('close')"
    >
        <div class="media-preview-wrapper">
            <v-img
                v-if="isImage"
                contain
                :lazy-src="media.thumbnail"
                :src="media.url"
            >
                <template #placeholder>
                    <v-row class="fill-height" align="center" justify="center">
                        <v-progress-circular indeterminate />
                    </v-row>
                </template>
            </v-img>

            <div v-else class="video-wrapper">
                <video
                    class="video"
                    controls
                    controlsList="nodownload nofullscreen noremoteplayback"
                    :poster="media.thumbnail"
                    disablepictureinpicture
                >
                    <source type="video/mp4" :src="media.url" />
                    Your browser does not support the video element. Please
                    update your browser version.
                </video>
            </div>
        </div>
        <div v-if="media.stockAuthor && media.pageUrl" class="pa-2 text-right">
            <a :href="media.pageUrl" target="_blank">
                {{ mediaTypeString }} by {{ media.stockAuthor }} on
                {{ previewMediaSource }}
            </a>
        </div>
    </modal>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { Modal } from '@/components/Modal';

import type { StockMedia } from '@/types/Media';

const MediaPreviewModalProps = Vue.extend({
    name: 'MediaPreviewModal',
    props: {
        media: {
            type: Object as PropType<StockMedia>,
            required: true
        }
    }
});

@Component({
    components: { Modal }
})
export default class MediaPreviewModal extends MediaPreviewModalProps {
    get isImage() {
        return this.media.type === 0;
    }

    get mediaTypeString() {
        if (this.isImage) {
            return 'Image';
        }
        return 'Video';
    }

    get previewMediaSource() {
        if (this.media.source === 3) {
            return 'Pixabay';
        }
        return 'Pexels';
    }
}
</script>

<style lang="scss" scoped>
.media-preview-wrapper::v-deep {
    height: 62vh;

    .v-image {
        background-color: $bonjour;
        height: 100%;
    }

    .video-wrapper {
        display: flex;
        height: 100%;
        background-color: $bonjour;
        align-items: center;

        video {
            width: 100%;
            object-fit: fill;
        }
    }
}
</style>
