import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"media-preview__container"},[_c(VRow,{staticClass:"media-preview__wrapper"},[_vm._l((_vm.mediaResources),function(resource,key){return _c(VCol,{key:key,staticClass:"media-preview__media pa-2",attrs:{"cols":"6","sm":"3"}},[_c('media-preview-list-actions',{attrs:{"resource":resource,"allow-edit":_vm.allowEdit,"allow-delete":_vm.allowDelete},on:{"feature":_vm.onFeature,"replace":_vm.onReplace,"remove":_vm.onRemove}}),_c(VImg,{staticClass:"media-preview__media-image rounded",attrs:{"height":"100%","src":_vm.getThumbnail(resource)},on:{"click":function($event){return _vm.setPreviewMedia(resource)}}})],1)}),(_vm.canAdd)?_c(VCol,{staticClass:"pa-2",attrs:{"cols":"6","sm":"3"},on:{"click":_vm.addMedia}},[_c('div',{staticClass:"media-preview__add-button"},[_c(VIcon,{staticClass:"mb-2",attrs:{"size":"50px"}},[_vm._v("fa-plus")]),_c('span',[_vm._v("Click to add media")])],1)]):_vm._e()],2),(_vm.previewMedia)?_c('media-preview-modal',{attrs:{"media":_vm.previewMedia},on:{"close":function($event){_vm.previewMedia = null}}}):_vm._e(),(_vm.canAdd || _vm.allowEdit)?_c('media-dialog',{ref:"MediaDialog",attrs:{"module-title":_vm.moduleTitle,"index":_vm.index,"action":_vm.action,"options":_vm.options,"allow-stock-video-search":_vm.allowStockVideoSearch,"allow-user-images":_vm.allowUserImages},on:{"confirm":_vm.onConfirm,"saved":_vm.onMediaSaved,"deleted":_vm.onMediaDeleted}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }