import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,{staticClass:"media-library__actions--wrapper"},[_c(VCol,{staticClass:"media-library__action-buttons px-sm-0",attrs:{"cols":"12","sm":"6"}},_vm._l((_vm.actions),function(action){return _c(VTooltip,{key:action.key,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"media-library__action-button",attrs:{"icon":"","disabled":action.disabled || _vm.requesting,"ripple":false},on:{"click":action.onClick}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":_vm.getIconSize(action),"disabled":action.disabled,"ripple":false}},[_vm._v(" "+_vm._s(action.icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(action.title))])])}),1),_c(VCol,{staticClass:"media-library__uploader d-flex justify-sm-end py-0",attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"uploader-button",on:{"click":_vm.browse}},[_c(VBtn,{staticClass:"mr-1",attrs:{"fab":"","x-small":"","color":"blue","elevation":"0"}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("plus")])],1),_c('span',{staticClass:"label text-body-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.uploaderLabel)+" ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }